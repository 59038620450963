$primary-color: #154442;
$background-color: #dff6f5;
$background-color-2: #3ca89b;

@font-face {
    font-family: 'LTFUNK';
    src: url('../..//leaguespartan-bold.woff');
}
@font-face {
    font-family: 'OPENSANS';
    src: url('../..//OpenSans-Regular.ttf');
}

.short {
	color: #037703 !important;
}
.medium {
	color: #c77d2e  !important;
}

 .long {
	color: #a82f2f  !important;
}

.ab-color {
    color: #3ca89b
}

body {
    background-color: $background-color;
    color: $primary-color;
    position: relative;
    display: block;
    margin: 0px;

}
.menu {

    display: flex;
    flex-direction: row;
    width: 100%;
    background:$primary-color;
    height: 100px;
}
@media screen and (min-width: 600px){

.mobile-menu-main {
width: 30% !important;
}



}
@media screen and (max-width: 600px){


    .mobile-item-gallery-full-screen-close {
      
        position: absolute;
        width: 100%;
        display: flex !important;
        justify-content: flex-end;
        color: white;
        font-size: 40px;
        padding-right: 20px;
        z-index: 999999;
      }
      
      .item-gallery-item-fullscreen.item-close {
        display: none !important;
    }
      
      span.mobile-item-gallery-fullscreen-close-button {
        margin-right: 8px;
      }
    
      
      
    .item-gallery-fullscreen-items {
        overflow-x: scroll;
    }

    img.item-gallery-fullscreen-image {
        object-fit: contain !important;
    }

    .park-landing-container {
        display: flex !important;
        flex-direction: column-reverse !important;
      }
      
      
      
      .park-landing-left {
        width: 100% !important;
      }
      
      
      
      .park-landing-right {
        width: 100% !important;
      }
      
      
      


    .imageHeaderTitle {
        font-size: 40px !important
    }
    p.disclaimer {
        display: none !important;
    }
    .home-page-numbers-inner-value {
        
        font-size: 28px !important;
    }
    .home-page-numbers-inner-label {
        font-size: 18px !important;
    }
    
    
    .home-page-content-image {display: none;}
.news-article-image { display: none}


.item-view-wrapper {

    padding-top: 0 !important;
}
    .menu {
        display: none;
    }

    .item-list-view-container {

        width: 100% !important;
        padding: 0px !important;
        zoom: 0.9; 
    }
    
    .item-list-view-button-container {

        flex-wrap: nowrap !important;
        overflow: scroll !important;
        justify-content: flex-start !important;
    }

    .button-primary {

        min-width: fit-content !important;
    }


    .item-view-wrapper {
        display: flex !important;
        flex-direction: column !important;
        flex-wrap: wrap !important;
        margin-top: 0px !important;
       }

       .item-desktop-left {


        width: 100% !important;
        border-right: none !important;
        padding: 0px !important;


       }
       .item-image {
        border-radius:  0 !important;

       }

       .item-img {
        border-radius: 0 !important;
       }
       .item-view-right {
        width: 100% !important;
        margin: 0px !important;
       }
       .show-item-content {
        margin: 10px !important;
       }
       .item-view-tab-inner {
        min-width: 113px;
       }
       .item-view-tab-nav {
        overflow-y: hidden;
        overflow-x: scroll;
        justify-content: flex-start !important;
        margin-left: 20px;
        margin-right: 20px;
       }
}

.App {

    font-family: 'OPENSANS';
}

.menuImage {


    height: 100%;
}

.logoImg {

    width: inherit;
    height: inherit;
}

.menuTitle {
    font-family: 'LTFUNK';
    text-align: left;
    width: 15%;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
color: $background-color;
}

.menuHeaders {

    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: unset;
    flex-grow: 1;

}

.menuHeader {

    width: auto;
  
    color: $background-color;
    font-size: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
  
    padding-left: 20px;
    padding-right: 20px;
   text-decoration :none;
}

.menuHeader:nth-child(1){

    ///border-left: $background-color 1px solid;

}
.menuHeader:hover {

text-decoration: underline $background-color;
transition: text-decoration 0.2s

}



.imageHeader {

height: 40vh;
background-color: black;
background-size: cover;
display: flex;
justify-content: center;
align-items: center;
}

.imageHeaderTitle {
font-family: 'LTFUNK';
    font-size: 60px;
    color:white;
text-align: center;

}


.hide {


    display:none !important;
    opacity: 0;
    transition: opacity 0.2s;
}

.show {

    display:flex !important;
    opacity: 1;
    transition:  opacity 0.2s;
}


.mega-menu {

font-family: 'OPENSANS';
    position:absolute;
    top: 100px;
    left: 0;
    float: left;
    height: 300px;
    display: flex;
    width: 100%;
    z-index: 1;
}

.mega-menu-left {
width: 40%;
    display: flex;
    flex-direction: column;
    background-color: $primary-color;
    color: white;
    min-height: 300px;
    border-right: 1px solid white;
}

.mega-menu-title {
    font-family: 'LTFUNK';

    font-size: 30px;
    text-align: left;
    padding: 20px;

}


.mega-menu-image-img {

    height: inherit;
    width: inherit;

}

.mega-menu-image {


    height: 20%;
    object-fit: cover;
    width: auto;
}

.mega-menu-description {


    text-align: left;
    padding: 20px;
}


.mega-menu-right {

width: 60%;
    background: $primary-color;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;

}

.mega-menu-item {

    color: white;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid white;
    padding-left: 10px;
    padding-bottom: 10px;
    width: 100%
}

.mega-menu-item:hover {

    color: $background-color-2;
    transition: all 0.2s
}

.mega-menu-item-title {
font-family: 'OPENSANS';

    font-size: 20px;
}



.list-view-item {
    color: $primary-color;
    width: 100%;
    height: 75px;
    background: $background-color;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: background-color 0.2s;
}

.list-view-item:hover {

    background-color: $background-color-2;
    transition: background-color 0.2s;


}

.list-view-container {

margin:10px;


}



.list-view-item-image {


    margin-left: 10px;
    height: 50px;
    border-radius: 12px;
    width: 50px;
    object-fit:  cover;
    display: inline-flex;
    align-items: center;

}

.list-view-item-image-img {
    border-radius: 12px;
    width: 50px;
    height: 50px;
    object-fit: cover;
}


.list-view-item-stacked-title {


    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 10px;
    align-items: flex-start;
}


.list-view-item-stacked-title-primary {


    font-size:  18px;
    font-family: 'LTFUNK';
}

.list-view-item-stacked-title-secondary {

font-size: 17px;
font-weight: 500;

}


.list-view-item-end {


    flex-grow: 1;
    text-align: right;
    margin-right: 40px;
    font-size: 20px;
    font-family: 'OPENSANS';
}


.list-container-title {

    text-align: left;
    margin: 20px;
    font-family: 'LTFUNK';
}


.image-placeholder {

    width: 80%;
    height: 80%;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #524f4f;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite



}



.gallery-item {


    width: inherit;
    height: inherit;
    
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

.gallery {

position: relative;
    overflow: hidden;
   z-index: 1;
   border-radius: 20px;

}

.gallery-information {


    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70px;
    background: linear-gradient(90deg, #000000c2, transparent);
    color: white;
    text-align: left;
    padding-left: 20px;
    font-size: 20px;
}



.gallery-image-container {
    display: inline-grid;
    grid-auto-flow: column;
    transition: transform 0.5s ease;
    width: inherit;
    height: inherit;
  }




  .gallery-dots {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .gallery-dot {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f5abab;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .gallery-dot.active {
    background-color: #154442 !important;
  }


.video-library-item {


    width: 100%;
    height: 150px;
    background: #bbbbbb;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: background-color 0.2s;

}

.video-library-item:hover {
    background: #6d6d6d;


}
.video-library-item-thumbnail {
      
       height: 128px;
       margin-left: 20px;
       border-radius: 20px;
}



.video-library-item-thumbnail-img {

    width: inherit;
    height: inherit;
    border-radius: 20px;
    transition: .2s ease;
}
.video-library-item-thumbnail-image {

    width: inherit;
    height: inherit;
   
}


.video-library-item-thumbnail-img:hover {

    filter:blur(1px);
   
}


.video-library-item-details {


    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-left: 20px;
    align-items: flex-start;
}

.video-library-item-end {
    flex-grow: 1;
    text-align: right;
    margin-right: 20px;
}

.video-library-item-details-title {
    font-family: 'LTFUNK';
    font-size: 30px;
}

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 


.item-view-wrapper {

    display: flex;
    padding-top: 60px;
}

.item-view-right {
    margin-left: 10px;
    width: 68%;
    margin-right: 10px
}

  .item-desktop-left {
    width: 32%;
    border-right: 1px solid gray;
    padding: 20px;
}


.large-image-lazy-load {

    
    width: 100px !important;
    height: 100px !important;
}
.item-img {

    height: auto;
    width: 100%;
    border-radius: 20px;
    object-fit: scale-down;
}

.item-image {
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    
}

.item-title {
    padding-top: 10px;
    padding-left: 20px;
    font-family: 'LTFUNK';
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.item-basic-info {
    padding-left: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.item-basic-info-inner {


    font-size: 18px;
    color: $primary-color;
}

.item-opening-times {
    height: 100px;
    display: block;
    border-top: 1px solid #3c3c3c;
}

.item-opening-times-inner {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-opening-times-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.item-opening-time-icon {
    font-size: 25px;
    color: $primary-color;
}

.item-opening-time-text {
    font-size: 20px;
    color: $primary-color;
}

.item-key-information-container {
    box-sizing: unset !important;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;
    border-top: 1px solid gray;
    padding-top: 20px;
    padding-bottom: 20px;
}

.item-key-information-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
}


.item-attraction-description {
    border-top: 1px solid #3c3c3c;
    display: block;
    font-size: 16px;
    color: $primary-color;;
    white-space: normal;
    text-align: center;
    text-overflow: clip;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 19px;
    border-bottom: 1px solid #3c3c3c;
}

.item-view-tab-nav {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}


.item-view-tab-inner {
    margin-right: 10px;
    width: 150px;
    text-align: center;
    background-color: $primary-color;
    -webkit-transition: background-color 0.25s;
    -moz-transition: background-color 0.25s;
    transition: background-color 0.25s;
    height: 30px;
    display: flex;
    font-size: 17px;
    padding: 8px;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    font-family: 'OPENSANS';
}


.item-view-tab-nav div:first-child{
    border-radius: 10px 0 0 10px;
}

.item-view-tab-nav div:last-child{
    border-radius: 0 10px 10px 0;
}

.item-view-tab-inner.selected {


    background-color: $background-color-2;
}

.show-item-content {
   display: block;

}

.hide-item-content {


   display: none !important;
}

span.stat-after {
    text-overflow: none;
    text-overflow: ellipsis;
   text-align:right;
    max-width: 60%;
    font-family: 'OPENSANS';
        font-size: 14px;
}


.aitem-stats p strong {
    
    font-family: "LTFUNK";
    font-weight: 100;
    margin-bottom: 5px;
    color: white;
        font-size: 2em;
}

.item-stats {
   
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    
}

.item-stats ol {

    margin: 0px;
    width: 90%;
    margin-block-start: 0px;
    list-style-type: none;
    padding-inline-start: 0px;

}
.item-stats ol li{
    
       min-height: 45px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
       border-radius: 10px;
    margin-bottom: 5px;
    padding-left: 12px;
       font-family: "LTFUNK";
       font-size: 14px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    padding-left: 20px;
    color: white;
    padding-right: 20px;

}

.ride-key-information {

    width: 90%;
}

.ride-key-infortmation-global {

    display: flex;
    flex-direction: column;
    align-items: center;
}
.ride-key-information-inner {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width:100%;
}

.ride-key-information-container {

    min-height: 45px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1px solid gray; */
    border-radius: 10px;
    margin-bottom: 5px;

}
.ride-key-information-title {
    font-family: "LTFUNk";
    /* font-family: "Mother Hen", sans-serif; */
    font-size: 14px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    padding-left: 20px;
    color:white;
}
.ride-key-information-end {
 
    display: flex;
    justify-content: flex-end;
    padding-inline-end: 10px;
        flex-grow: 5;
min-width:30%;
color: white;
}

.list {

    margin-block-start: 0px;
    padding-inline-start: 0px;
}

.item-list-view-card {

width: 200px;
height: 190px;

border-radius: 20px;
display: flex;
flex-direction: column;
margin: 4px;
color: #215f58;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.item-list-view-card:hover

{
    transition: 0.5s all;
    box-shadow: 0 0em 1em 0px rgb(255 255 255 / 53%);
}
.item-list-view-image {


    width: inherit;
    height: 100px;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}


.image-placeholder-square {

    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #def4f2;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    aspect-ratio: 1/1;


}


.image-placeholder-square-container {

    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #215f58;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.item-list-view-container {

    display: flex;
    /* justify-items: center; */
    /* grid-auto-rows: auto; */
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 30px;
}


.item-list-view-text-container {
    background-color:#def4f2;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-grow: 1;
    padding-left: 7px;
    padding-right: 7px;
    font-family: 'OPENSANS';
    padding-top: 4px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.item-list-view-card-sub-title{


    font-size: 14px
}

.item-list-view-card-title {

    font-weight: bold;
    letter-spacing: 0px;
}

.item-list-view-card-queuetime {

    font-weight: bold;
}

a {

    color: unset;
    cursor: pointer;
    text-decoration: none;

}


.item-list-view {

    display: flex;
    /* width: 80%; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.item-list-view-button-container {


    display: flex;
    width: 100%;
    height: 200px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: 120px;
}

.button-primary {


    min-width: 103px;
    height: 45px;
    background: #215f58;
    border-radius: 20px;
    border-style: none;
    margin: 2px;
    font-family: "LTFUNK";
    color: white;
    font-size: 17px;


}

.divider {
    border-right: 1px solid grey;
}



.page-loader {

    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    
}

.page-loader-spinner {

    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #def4f2;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    aspect-ratio: 1/1;
}

.loading-error {

    height: 50px;
    display: flex;
    background: #d13232;
    align-items: center;
    padding: 14px;
    border-radius: 9px;



}

.page-location-container {

    height: 58px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}


.page-location-inner {

    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: 20px;
    justify-content: flex-start;
}

.page-location-child {

    font-family: 'LTFUNK';
    padding-left: 5px;
    padding-right: 5px;
    border-right: 1px solid $background-color-2

}



.page-location-child:first-child{

    padding-left:0px;

}
.page-location-child:last-child{

    border-right: none;
}


.mobile-menu-navbar {

    width: 100%;
    height: 100px;
    background: $primary-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:0;
    z-index: 99999;
}

.mobile-nav-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.mobile-nav-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.menu-toggle {

    position: absolute;
    left: 0px;
    height: 100px;
    width: 75px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: $background-color;
}


.mobile-menu-main {

    height: 100vh;
    background:$primary-color;
    width: 59%;
    position: fixed;
color: $background-color;
z-index: 9999;
}

.mobile-menu-dropdown {
    
}

.mobile-menu-header {

    height: 50px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'LTFUNK';
    justify-content: space-between;
    border-bottom: 1px solid $background-color;
    padding-left: 15px;
    padding-right: 15px;
}

.mobile-menu-dropdown-body-item {
    margin-left: 15px;
}

.mobile-menu-dropdown-body-item {
    margin-left: 15px;
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-right: 15px
}

.mobile-menu-dropdown-body {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #072221;
}


.mobile-menu-dropdown-items {
    margin-left: 15px;
    margin-top: 16px;
}

.mobile-menu-dropdown-item {
    height: 33px;
}


.news-article-item {
    border-radius: 15px;
    display: flex;
    align-items: center;
    height: 300px;
    width: 90%;
    background-color: $primary-color;
    color: $background-color;
    margin-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.news-article-image {

    width: 300px;
    height: 100%;
    object-fit: cover;

}
img.news-article-img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.news-article-item-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-grow: 1;
margin-left: 10px;
margin-right: 10px;

}

.news-article-title {

font-size: 25px;
font-family: "LTFUNK";
padding-top: 20px;

}


.news-article-description {
    padding-top: 20px;
height: 30%;
flex-grow: 1;
overflow: hidden;
}

.news-articles-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 25px;
    margin-bottom: 15px;
}

.button-container-news {

    display: flex;
    justify-content: flex-end;
}

.button-news {
    margin-bottom: 5px;
    
        height: 51px;
        width: 140px;
        background: $background-color-2;
        display: flex;
        color: white;
        border-radius: 20px;
        align-items: center;
        justify-content: center;
    
}


.news-article-info-container {
    border-bottom: 1px solid black;
    padding-bottom: 20px;
    padding-left: 20px;
  }
  
  
  
  .news-article-large-title {
    font-family: 'LTFUNK';
    font-size: 35px;
  }
  
  
  
  .news-article-author {
    font-size: 25px;
  }
  
  

  .news-article-published-date {
    font-size: 20px;
  }
  
 
  
  .news-article-content {
    padding: 45px;
  }
  
  .news-article-header {
    padding-top: 20px
  }


  span.news-article-nav-button {
    height: 50px;
    width: 50px;
    display: block;
    background: $primary-color;
    color: $background-color;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 13px;
}


.news-nav-button-container {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.custom-page-container {

    padding:15px
}



.footer {
    height: 100px;
    background: #154442;
    margin-top: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 12px;
    color: $background-color
}
.main-body {
    margin-top:100px;
    overflow-x: hidden;
    min-height: calc(100vh - 250px);
}





.home-page-numbers {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $primary-color;
  
}



.home-page-numbers-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.home-page-numbers-inner-value {
  font-family: 'LTFUNK';
  font-size: 40px;
}



.home-page-numbers-inner-label {
  font-size: 23px;
}

.home-page-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 24px;
  }
  
  
  
  .home-page-content-text {
    flex-grow: 1;
    font-size: 18px;
  }
  
  
  
  .news-article-home-page {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .news-article-home-page-title {
    margin: 20px;
    font-size: 28px;
    font-family: 'LTFUNK';
  }
  

  .social-media-button-img {

    
width: 50px;
height: 50px;
  }
  
  .social-buttons {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding-right: 30px;
}


.social-media-button {
    margin-left: 20px;
}


.area-view-poi-view {
   
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    width: 100%;
    zoom: 0.9;
}


span.area-view-poi-view-title {
    width: 100%;
    font-family: 'LTFUNK';
    font-size: 28px;
}

span.item-basic-info-inner.area-view {
    margin-bottom: 20px;
    /* padding-bottom: 20px; */
    display: block;
    padding-left: 20px;
}


.park-landing-left {
    width: 70%;
}

.park-landing-container {
    display: flex;
    flex-direction: row;
}


.park-landing-right {

    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 51px;
}



.park-landing-left {
    width: 60%;
  }
  
  
  
  .park-landing-container {
    display: flex;
    flex-direction: row;
  }
  
 
  
  .todays-park-hours-container {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $primary-color;
    color: white;
    width: 80%;
    padding: 15px;
    border-radius: 15px;
    font-family: 'OPENSANS';
    max-width: 500px;
  }
  
  
  
  .todays-hours-title {
    font-family: 'OPENSANS';
  }
  
  
  
  .todays-hours-content {
    font-size: 33px;
  }
  
  
  
  .further-hours {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: center;
  }
  
  
  
  .further-hours-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
    border-top: white solid 1px;
    padding-top: 15px;
  }
  
  .planned-ride-closures {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #5d0101;
    color: white;
    width: 80%;
    padding: 15px;
    border-radius: 15px;
    font-family: 'OPENSANS';
    margin-top:20px;
    max-width: 500px;
  }
  .planned-closures-title {
    font-size: 20px;
    margin-bottom: 20px;
    font-family: 'LTFUNK';
}

  .latest-information {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $primary-color;
    color: white;
    width: 80%;
    padding: 15px;
    border-radius: 15px;
    font-family: 'OPENSANS';
    margin-top:20px;
    max-width: 500px;
  }
  
  .latest-park-news-article {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $primary-color;
    color: white;
    width: 80%;
    padding: 15px;
    border-radius: 15px;
    font-family: 'OPENSANS';
    margin-top:20px;
    max-width: 500px;
  }

  .latest-park-news-article-title {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
}

  img.latest-park-news-article-img {
    width: 100%;
    border-radius: 10px;
}


.latest-park-news-article-content {
    margin-bottom: 20px;
}


.planned-closure-content-container {
    width: 100%;
}

.park-alert {

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #154442;
        color: white;
        width: 80%;
        padding: 15px;
        border-radius: 15px;
        font-family: "OPENSANS";
        margin-bottom: 20px;
        max-width: 500px;
    
}


.latest-park-news-article-content {
    margin-bottom: 20px;
   
    text-overflow: ellipsis;
    
    width: calc(100%);
    display: -webkit-box;

    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}



.view-on-queue-time-hub-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  
  
  .view-on-qth-inner {
    width: 60%;
    background: black;
    height: 60px;
    border-radius: 20px;
    max-width: 230px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  
  
  img.view-on-qth-img {
    height: 87%;
    margin-left: 4px;
  }
  
  

  span.view-on-qth-text {
    color: white;
    font-family: 'LTFUNK';
    font-size: 18px;
    margin-left: 9px;
  }
  
  
  .item-view-show-times-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  
  
  .item-view-show-time-item {
    height: 42px;
    width: 85px;
    background: $primary-color;
    display: flex;
    flex-direction: column;
  
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 10px;
    font-size: 19px;
    font-family: 'OPENSANS';
    margin-top:20px
  }
  
  
  .item-show-show-times-title {
    font-family: 'LTFUNK';
    font-size: 20px;
    padding-left: 18px;
    border-top: 1px solid grey;
    padding-top: 20px;
    text-align: center;
    
  }
  
  
  .item-gallery-container {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 0fr));
    width: 100%;
    justify-items: center;
    justify-content: center;
}
  
  
  .item-gallery-item {
    width: 250px;
    height: 167px;
  }
  
  
  
  img.item-gallery-item-image {
    width: inherit;
    height: inherit;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .item-gallery-description-container {
    position: relative;
    bottom: 54px;
    background: linear-gradient(90deg, $primary-color, transparent);
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
}
  


body.no-scroll {
    overflow: hidden;
  }


  .item-gallery-full-view {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: black;
    top: 0;
    left: 0;
    z-index: 99999999999;
    margin-top: -100px;
    overflow: hidden;
    scrollbar-width: 0px;
  }
  
  img.item-gallery-fullscreen-image {
    width: 100%;
    height: 100%;
    object-fit: none;
}


.item-gallery-fullscreen-image-container {
    height: calc(100% - 150px);
  }
  
  
  
  .item-gallery-controlsinfo-container {
    height: 150px;
    background: $primary-color;
  }
  
  

  .item-gallery-item-fullscreen {
    height: 100px;
    width: 100px;
   

    border: 5px solid transparent;
    border-radius: 28px;
  }
  
  
  
  img.item-gallery-item-image-fullscreen {
    height: inherit;
    width: inherit;
    object-fit: cover;
    border-radius: 20px;
  }
  
  
  
  .item-gallery-fullscreen-items {
    display: grid;
    grid: space-evenly;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    /* grid-template-columns: repeat(auto-fill, minmax(250px, 0fr)); */
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
  }
  
  
  .item-gallery-fullscreen-back {
    position: absolute;
    height: calc(100% - 150px);
    background: linear-gradient(270deg, transparent, #15444224);
    top: 0;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 50px;
  }
  
  .item-gallery-fullscreen-forward {
    position: absolute;
    height: calc(100% - 150px);
    background: linear-gradient(90deg, transparent, #15444224);
    top: 0;
    right: 0;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 50px;
  }
  
  .item-gallery-item-fullscreen.selected-gallery-item {
    border: 5px solid white;
    border-radius: 28px;
  }

  
  
  .item-gallery-item-fullscreen.item-close {
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: white;
    font-family: 'LTFUNK';
    /* border-right: 1px solid white; */
  }
  

  .item-gallery-fullscreen-description {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    /* left: 25%; */
    bottom: 150px;
    background: #000000bd;
    height: auto;
    padding: 15px;
    color: white
  }
  

  .mobile-item-gallery-full-screen-close {
display: none;
  }